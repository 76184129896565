// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const LATBaseUrl = 'https://prd.licmgmtapi.lm.xomsvcs.com/';
export const measuresRegistrationId = '24d81416bf7f4ea29a341becc44668b5';

export const protectedResourceMap: [string, string[]][] = [
    [
        'https://graph.microsoft.com/',
        [
            'user.read'
        ]
    ],
    [
        LATBaseUrl,
        [
            'https://EMCloudAD.onmicrosoft.com/714d6638-cfc0-425f-8378-caf7aad95e59/user_impersonation'
        ]
    ]
];

export const environment = {
    production: true,
    msalconfig: {
        clientID: '1bb22b1d-e5a4-4445-9f3b-d313e465514d',
        consentScopes: [
            'user.read',
            'openid',
            'profile',
            'https://EMCloudAD.onmicrosoft.com/714d6638-cfc0-425f-8378-caf7aad95e59/user_impersonation'
        ],
        authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/',
        protectedResourceMap,
        redirectUri: 'https://licenseautomation.lm.xomsvcs.com',
        storeAuthStateInCookie: true,
        cacheLocation: 'localStorage',
    },
}







/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
